import { useQuery } from '@apollo/client';
import React, { useEffect, useState, ReactNode } from 'react';
import {
    Avatar,
    Button,
    Composite,
    ContextWindow,
    Heading,
    Icon,
    IconName,
    Illustration,
    Level,
    ProgressIndicator,
    ProgressType,
    Size,
    Text,
    TextType,
    Variant,
    useTheme,
} from '@defa/defa-component-library';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { logos } from '../../assets/logos';
import { useAuthState } from '../../auth';
import { THEME_INDEX } from '../../constants';
import i18n from '../../i18n';
import { useOrganization } from '../../utils/hooks';

import { Header as Root, HeaderItemRoot, MenuItemRoot, Nav } from './header.styles';
import { ADMIN, AdminResponseType } from './header.queries';

export function MenuItem({
    icon,
    text,
    onClick,
    color,
    tid,
    avatar,
    disabled,
    loading = false,
}: {
    icon?: IconName;
    text: string;
    onClick?: () => void;
    color?: string;
    tid?: string;
    avatar?: ReactNode;
    disabled?: boolean;
    loading?: boolean;
}) {
    return (
        <MenuItemRoot
            disabled={disabled}
            onClick={disabled ? () => {} : onClick}
            data-test-id={tid}
        >
            <Composite justify="flex-start">
                {loading && <ProgressIndicator type={ProgressType.DONUT_LOADING} progress={50} />}
                {avatar}
                {icon && !loading && <Icon icon={icon} size={16} color={color} />}
                <Text type={TextType.description} color={color}>
                    {text}
                </Text>
            </Composite>
        </MenuItemRoot>
    );
}

export function HeaderItem({
    text,
    image,
    icon,
    href,
    pathMatch,
    color,
    subHeader,
    tid,
}: {
    text: string;
    icon?: IconName;
    image?: string;
    href?: string;
    pathMatch?: string[] | string;
    color?: string;
    subHeader?: string;
    tid?: string;
}) {
    const theme = useTheme();
    const match = useRouteMatch<{ id?: string }>(pathMatch || '');
    const history = useHistory();
    const { isExact } = match || {};
    const selected = match && isExact;

    return (
        <HeaderItemRoot data-test-id={tid} onClick={() => history.push(href || '/')}>
            {image && <Illustration src={image} centerInContainer={false} />}
            {icon && (
                <Icon
                    size={20}
                    icon={icon}
                    color={color || (selected ? theme.actionLinkTextColor : theme.tableHeaderColor)}
                />
            )}
            <Heading
                level={Level.h4}
                color={color || (selected ? theme.actionLinkTextColor : theme.tableHeaderColor)}
            >
                {text}
            </Heading>
            {subHeader && (
                <Text type={TextType.descriptionSmall} color={theme.listHeaderColor}>
                    {subHeader}
                </Text>
            )}
        </HeaderItemRoot>
    );
}

export function Header() {
    const { logout } = useAuthState();

    const history = useHistory();

    const theme = useTheme();

    const [selectedOrganization] = useOrganization();

    const [menuPositioningElement, setMenuPositioningElement] = useState<HTMLElement | undefined>(
        undefined
    );
    const { refetch: refetchAdmin, data: adminData, loading } = useQuery<AdminResponseType>(ADMIN);
    const { admin, organizations = [] } = adminData ?? {};

    useEffect(() => {
        refetchAdmin();
    }, [refetchAdmin]);

    useEffect(() => {
        if (!loading && !admin?.acceptedPrivacyConditions) {
            history.push('/setup/account');
        }
    }, [admin, history, loading]);

    return (
        <Root>
            <Nav>
                <HeaderItem
                    tid="header-item-logo"
                    text={i18n.t('Header.Item')}
                    image={logos[THEME_INDEX]}
                    href="/"
                    color="black"
                    subHeader={i18n.t('Header.SubHeader')}
                />
            </Nav>
            {selectedOrganization && (
                <Nav>
                    <HeaderItem
                        tid="header-item-dashboard"
                        text={i18n.t('Dashboard.Overview')}
                        icon="bolt"
                        href="/"
                        pathMatch={['/charge-system/:id', '/']}
                    />
                    <HeaderItem
                        tid="header-item-reports"
                        text={i18n.t('Dashboard.Reports')}
                        icon="reports"
                        href="/reports"
                        pathMatch={['/reports/:id/:year/:month', '/reports']}
                    />
                </Nav>
            )}
            <Nav>
                <Composite fillParent={false} gap={0} childMargin={0} justify="end">
                    {selectedOrganization && organizations.length > 1 && (
                        <Button
                            name="organizations-menu-item"
                            icon="toggle"
                            size={Size.SMALL}
                            variant={Variant.TEXT}
                            text={
                                organizations?.find((org) => org.id === selectedOrganization)
                                    ?.name ?? i18n.t('Organizations.Navigation')
                            }
                            iconUseMargin
                            tooltip={i18n.t('Organizations.Navigation')}
                            onClick={() => history.push('/organizations')}
                        />
                    )}
                    {selectedOrganization && (
                        <>
                            <Button
                                name="support-menu-item"
                                icon="questionCircle"
                                size={Size.SMALL}
                                variant={Variant.TEXT}
                                tooltip={i18n.t('Header.Account.Support')}
                                onClick={() => history.push('/support')}
                                padding={`${theme.spacing(2)} ${theme.spacing(1)}`}
                            />
                            <Button
                                name="organization-settings-menu-item"
                                icon="settings"
                                size={Size.SMALL}
                                variant={Variant.TEXT}
                                tooltip={i18n.t('Header.OrganizationSettings')}
                                onClick={() => history.push('/settings/organization')}
                                padding={`${theme.spacing(2)} ${theme.spacing(1)}`}
                            />
                        </>
                    )}
                    <Avatar
                        tid="loggedin-user-menu"
                        user={{
                            email: admin?.email ?? 'n@n.com',
                            name: `${admin?.firstName} ${admin?.lastName}`,
                        }}
                        mini
                        onClick={(e) => {
                            const { target } = e;
                            setMenuPositioningElement(
                                menuPositioningElement ? undefined : (target as HTMLElement)
                            );
                        }}
                    />
                </Composite>
            </Nav>
            <ContextWindow
                positioningElement={menuPositioningElement}
                onClosePress={() => {
                    setMenuPositioningElement(undefined);
                }}
                show={!!menuPositioningElement}
                side="left"
                maxWidth="210px"
                autoHeight
                bodyContent={
                    <>
                        <MenuItem
                            tid="settings-menu-item"
                            icon="user"
                            text={i18n.t('Header.Account.Settings')}
                            color={theme.textColor}
                            onClick={() => history.push('/settings/account')}
                        />
                        <MenuItem
                            tid="logout-menuitem"
                            icon="logout"
                            text={i18n.t('Header.Account.LogOut')}
                            color={theme.errorColor}
                            onClick={() => logout()}
                        />
                    </>
                }
            />
        </Root>
    );
}
