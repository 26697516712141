import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { Dashboard } from '../dashboard';
import { OrganizationInfo } from '../onboarding/organization-info';
import { Done } from '../onboarding/done';
import { PaymentMethods } from '../onboarding/payment-methods';
import { Subscription } from '../onboarding/subscription';
import { Root } from './portal.styles';
import { Organizations } from '../organizations';
import { ChargeSystem } from '../charge-system';
import { RearrangeGroups } from '../rearrange-groups';
import { Reports } from '../reports';
import { Setup } from '../setup';
import { Support } from '../support';
import { Settings } from '../settings';
import { SetupAccount } from '../setup/account';
import { NoOrganization } from '../no-organization';

interface PortalProps extends RouteComponentProps<{}>, React.Props<{}> {}

export const Portal: React.FunctionComponent<any> = ({ match }: PortalProps) => (
    <Root>
        <Switch>
            <Route exact path={`${match.url}`} component={Dashboard} />
            <Route path={`${match.url}settings`} component={Settings} />
            <Route path={`${match.url}support`} component={Support} />
            <Route path={`${match.url}onboarding/organization/:id?`} component={OrganizationInfo} />
            <Route path={`${match.url}onboarding/subscription/:id`} component={Subscription} />
            <Route path={`${match.url}onboarding/payment-methods/:id`} component={PaymentMethods} />
            <Route path={`${match.url}onboarding/done/:id`} component={Done} />
            <Route path={`${match.url}setup/account`} component={SetupAccount} />
            <Route path={`${match.url}charge-system/:id/setup`} component={Setup} />
            <Route path={`${match.url}charge-system/:id/rearrange`} component={RearrangeGroups} />
            <Route path={`${match.url}charge-system/:id`} component={ChargeSystem} />
            <Route path={`${match.url}organizations`} component={Organizations} />
            <Route path={`${match.url}reports`} component={Reports} />
            <Route exact path={`${match.url}no-organization`} component={NoOrganization} />
        </Switch>
    </Root>
);
