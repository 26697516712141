import React, { useState } from 'react';
import {
    Button,
    Composite,
    ContextWindow,
    Heading,
    Icon,
    Level,
    useTheme,
    Variant,
    getColorByInitials,
    formatInitials,
} from '@defa/defa-component-library';
import { Root, MiniAvatar, Text, Input, InfoBoxRoot } from './charge-system-selection.styles';
import { ChargeSystem } from '../../models';
import i18n from '../../i18n';

function ChargeSystemAvatar({ description }: { description: string }) {
    const theme = useTheme();
    const initials = formatInitials(description);
    const color = getColorByInitials(initials, theme);
    return (
        <InfoBoxRoot color={color}>
            <Heading level={Level.h4}>{initials.toLocaleUpperCase()}</Heading>
        </InfoBoxRoot>
    );
}

function ChargeSystemItem({
    cs,
    onChange,
    activeIds,
}: {
    cs: ChargeSystem;
    activeIds: string[];
    onChange: () => void;
}) {
    const { id, name } = cs;
    const theme = useTheme();
    const initials = formatInitials(name);
    const color = getColorByInitials(initials, theme);

    return (
        <Root>
            <MiniAvatar color={color}>{initials.toLocaleUpperCase()}</MiniAvatar>
            <Text htmlFor={`cs-selection-${id}`}>{name}</Text>
            <Input
                id={`cs-selection-${id}`}
                type="checkbox"
                checked={activeIds.includes(id)}
                onChange={onChange}
                name="cs-items"
            />
        </Root>
    );
}

function SelectAllItem({ onClick }: { onClick: () => void }) {
    const theme = useTheme();

    return (
        <Root onClick={onClick}>
            <Icon icon="grid" size={24} color={theme.foreground.default} />
            <Text>{i18n.t('Reports.SelectionAll')}</Text>
        </Root>
    );
}

export function ChargeSystemSelection({
    activeIds,
    chargeSystems,
    onChange,
}: {
    activeIds: string[];
    chargeSystems: ChargeSystem[] | undefined;
    onChange: (csIds: string[]) => void;
}) {
    const [menuPositioningElement, setMenuPositioningElement] = useState<HTMLElement | undefined>();

    const titleText =
        activeIds.length === 1
            ? chargeSystems?.find(({ id }) => id === activeIds.at(0))?.name ?? ''
            : i18n.t('Reports.ChargeSystemSelectionTitle', { count: activeIds.length });

    const title = (
        <Composite justify="end">
            {(activeIds.length ?? 0) > 1 ? (
                <Icon icon="grid" size={32} />
            ) : (
                <ChargeSystemAvatar
                    description={
                        chargeSystems?.find(({ id }) => id === activeIds.at(0))?.name ?? 'NN'
                    }
                />
            )}
            <Heading level={Level.h3}>{titleText}</Heading>
        </Composite>
    );

    return !chargeSystems || chargeSystems.length === 1 ? (
        title
    ) : (
        <>
            <Button
                fillParent={false}
                iconAfter="chevron"
                iconUseMargin
                variant={Variant.TEXT}
                padding="0"
                onClick={(e?: MouseEvent) => {
                    const { target } = e || ({} as MouseEvent);
                    setMenuPositioningElement(
                        menuPositioningElement ? undefined : (target as HTMLElement)
                    );
                }}
                showInPrint
            >
                {title}
            </Button>
            <ContextWindow
                noPadding
                positioningElement={menuPositioningElement}
                onClosePress={() => {
                    setMenuPositioningElement(undefined);
                }}
                show={!!menuPositioningElement}
                autoHeight
                side="bottom"
                bodyContent={
                    <>
                        <SelectAllItem
                            onClick={() => {
                                setMenuPositioningElement(undefined);
                                if (
                                    chargeSystems &&
                                    activeIds.length === chargeSystems?.length &&
                                    activeIds.length > 1
                                ) {
                                    onChange([chargeSystems.at(0)?.id]);
                                } else {
                                    onChange(chargeSystems?.map(({ id }) => id) ?? []);
                                }
                            }}
                        />
                        {chargeSystems?.map((cs) => (
                            <ChargeSystemItem
                                key={cs.id}
                                cs={cs}
                                activeIds={activeIds}
                                onChange={() => {
                                    let newIds = [...activeIds];
                                    if (newIds.includes(cs.id) && newIds.length > 1) {
                                        newIds = activeIds.filter((id) => id !== cs.id);
                                    } else if (!newIds.includes(cs.id)) {
                                        newIds = activeIds.concat(cs.id);
                                    }
                                    onChange(newIds);
                                }}
                            />
                        ))}
                    </>
                }
            />
        </>
    );
}
