import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
    Column,
    Currency,
    useTheme,
    formatNumberByLocale,
    getCurrencyFormattedCost,
} from '@defa/defa-component-library';
import { StripeStatus } from '../../models/organization';
import i18n from '../../i18n';
import {
    ChargerUsage,
    ChargingSessionData,
    ReportOutput,
    ReportPerDay,
    UserUsage,
    VisitorRfidUsage,
    PayoutReportData,
    ReportTypeField,
    ReportOutputItem,
} from './reports.types';
import { generateCsv } from '../../utils/csv';
import { CHARGING_SESSIONS, PAYOUT_REPORT } from './reports.queries';
import { MenuItem } from '../../fractions/header';
import { formatDateByLocale } from '../../utils/date';

export type ExcludesUndefined = <T>(x: T | undefined) => x is T;

export const calculateAmount = (
    reports: ReportOutput[] | undefined,
    item: ReportOutputItem,
    field: ReportTypeField
) => (reports ? reports.reduce((t, r) => t + (r?.[item]?.[field].amount ?? 0), 0) : 0);

export const calculateAverageAmount = (
    reports: ReportOutput[] | undefined,
    item: ReportOutputItem,
    field: ReportTypeField
) => (reports ? calculateAmount(reports, item, field) / reports.length : 0);

export const getAmountUnit = (
    reports: ReportOutput[] | undefined,
    item: ReportOutputItem,
    field: ReportTypeField
) => (reports ? reports.at(0)?.[item]?.[field]?.unit ?? '' : '');

export const getLocalDate = (date: Date) => {
    if (navigator.language) {
        return formatDateByLocale(date);
    }
    return date.toDateString();
};

export const getLocalDay = (day: string) => {
    const date = new Date(day);
    return getLocalDate(date);
};

export const localDecimals = (amount: number, minAmount: number = 1000, decimals: number = 3) => {
    if (navigator.language && amount) {
        return formatNumberByLocale(navigator.language, amount, amount > minAmount ? 0 : decimals);
    }
    return 0;
};

export const localCurrency = (amount: number, currency: Currency) => {
    const theAmount = amount ?? 0;
    try {
        if (navigator.language && currency) {
            const localAmount = getCurrencyFormattedCost(
                navigator.language,
                currency,
                theAmount,
                theAmount > 1000 ? 0 : 2
            ).normalize('NFKC');
            return localAmount;
        }
    } catch (e) {
        console.error('localCurrency fail', currency);
    }
    return '0';
};

export const chargerUsageColumns = (stripeStatus: StripeStatus) =>
    [
        Column('id'),
        Column('displayName'),
        Column('sessions'),
        Column('totalTime'),
        Column('totalEnergy'),
        stripeStatus === StripeStatus.ENABLED ? Column('revenue') : '',
    ].filter(Boolean);

export const userUsageColumns = (stripeStatus: StripeStatus) =>
    [
        Column('user'),
        Column('phone'),
        Column('sessions'),
        Column('totalTime'),
        Column('totalEnergy'),
        stripeStatus === StripeStatus.ENABLED ? Column('revenue') : '',
    ].filter(Boolean);

export const visitorRfidUsageColumns = [
    Column('keyId'),
    Column('description'),
    Column('sessions'),
    Column('totalTime'),
    Column('totalEnergy'),
];

export const chargerUsageColumnNames = (stripeStatus: StripeStatus) =>
    [
        i18n.t('Reports.TableSection.ID'),
        i18n.t('Reports.TableSection.DisplayName'),
        i18n.t('Reports.TableSection.Sessions'),
        i18n.t('Reports.TableSection.TotalTime'),
        i18n.t('Reports.TableSection.TotalEnergy'),
        stripeStatus === StripeStatus.ENABLED ? i18n.t('Reports.TableSection.Revenue') : '',
    ].filter(Boolean);

export const userUsageColumnNames = (stripeStatus: StripeStatus) =>
    [
        i18n.t('Reports.TableSection.User'),
        i18n.t('Reports.TableSection.Phone'),
        i18n.t('Reports.TableSection.Sessions'),
        i18n.t('Reports.TableSection.TotalTime'),
        i18n.t('Reports.TableSection.TotalEnergy'),
        stripeStatus === StripeStatus.ENABLED ? i18n.t('Reports.TableSection.Revenue') : '',
    ].filter(Boolean);

export const visitorRfidUsageColumnNames = [
    i18n.t('Reports.TableSection.KeyId'),
    i18n.t('Reports.TableSection.Description'),
    i18n.t('Reports.TableSection.Sessions'),
    i18n.t('Reports.TableSection.TotalTime'),
    i18n.t('Reports.TableSection.TotalEnergy'),
];

export const sessionsColumnNames = (unit: string = '') => [
    i18n.t('Reports.TableSection.ChargeSystem'),
    i18n.t('Reports.TableSection.ChargeSystemId'),
    i18n.t('Reports.TableSection.User'),
    i18n.t('Reports.TableSection.Phone'),
    i18n.t('Reports.TableSection.KeyId'),
    i18n.t('Reports.TableSection.ChargerID'),
    i18n.t('Reports.TableSection.ConnectorID'),
    i18n.t('Reports.TableSection.StartChargingDate'),
    i18n.t('Reports.TableSection.StartChargingTime'),
    i18n.t('Reports.TableSection.EndChargingDate'),
    i18n.t('Reports.TableSection.EndChargingTime'),
    i18n.t('Reports.TableSection.SessionTime'),
    i18n.t('Reports.TableSection.Energy', {
        unit,
    }),
    i18n.t('Reports.TableSection.SessionAmountIncVAT'),
    i18n.t('Reports.TableSection.SessionAmountExVAT'),
    i18n.t('Reports.TableSection.VatAmount'),
    i18n.t('Reports.TableSection.PaymentProvider'),
    i18n.t('Reports.TableSection.PaymentStatus'),
    i18n.t('Reports.TableSection.CloudChargeTransactionFee'),
    i18n.t('Reports.TableSection.PayoutAmount'),
    i18n.t('Reports.TableSection.PaymentIntentID'),
    i18n.t('Reports.TableSection.PaymentID'),
    i18n.t('Reports.TableSection.TransactionID'),
];

export const getFilename = (prefix: string, items: string[] | undefined, start: Date, end: Date) =>
    `${prefix} ${items?.join(', ')} ${getLocalDate(start)}-${getLocalDate(end)}.csv`;

export const downloadChargerUsageCsv = (
    reports: ReportOutput[] | undefined,
    stripeStatus: StripeStatus,
    vatLiable = false,
    defaultCurency = '',
    from: Date,
    to: Date
) => () => {
    if (!reports || reports.length === 0) {
        return;
    }
    const timeUnit = reports ? reports[0]?.total?.occupiedTime?.unit : '';
    const energyUnit = reports ? reports[0]?.total?.energyConsumption?.unit : '';
    const revenueUnit = reports ? reports[0]?.total?.revenue?.unit : '';
    const chargerUsageCSVColumnNames = [
        i18n.t('Reports.TableSection.ID'),
        i18n.t('Reports.TableSection.DisplayName'),
        i18n.t('Reports.TableSection.Sessions'),
        `${i18n.t('Reports.TableSection.TotalTime')} (${timeUnit})`,
        `${i18n.t('Reports.TableSection.TotalEnergy')} (${energyUnit})`,
        stripeStatus === StripeStatus.ENABLED
            ? `${i18n.t('Reports.TableSection.Revenue')} (${revenueUnit} ${i18n.t(
                  vatLiable ? 'Reports.TableSection.ExcludeVAT' : 'Reports.TableSection.IncludeVAT'
              )})`
            : '',
    ].filter(Boolean);
    const rows = [chargerUsageCSVColumnNames];
    if (reports) {
        reports.forEach((report) =>
            report?.data?.chargerUsage.map((item: ChargerUsage) =>
                rows.push(
                    [
                        item.alias,
                        `${item.displayName} `,
                        `${item.chargingSessions.amount}`,
                        `${localDecimals(item.occupiedTime.amount ?? 0, 100, 1)}`,
                        `${localDecimals(item.energyConsumption.amount)}`,
                        stripeStatus === StripeStatus.ENABLED
                            ? `${localCurrency(
                                  item.revenue.amount,
                                  item.revenue.unit || defaultCurency
                              )}`
                            : '',
                    ].filter(Boolean)
                )
            )
        );
    }
    generateCsv(
        rows,
        getFilename(
            'Charger details',
            reports?.map((r) => r?.name ?? ''),
            from,
            to
        )
    );
};

export const downloadUserUsageCsv = (
    reports: ReportOutput[] | undefined,
    stripeStatus: StripeStatus,
    vatLiable = false,
    defaultCurency = '',
    from: Date,
    to: Date
) => () => {
    if (!reports || reports.length === 0) {
        return;
    }
    const revenueUnit = reports ? reports[0]?.total?.revenue?.unit : '';
    const columnNames = [
        i18n.t('Reports.TableSection.User'),
        i18n.t('Reports.TableSection.Phone'),
        i18n.t('Reports.TableSection.Sessions'),
        `${i18n.t('Reports.TableSection.TotalTime')}`,
        `${i18n.t('Reports.TableSection.TotalEnergy')}`,
        stripeStatus === StripeStatus.ENABLED
            ? `${i18n.t('Reports.TableSection.Revenue')} (${revenueUnit} ${i18n.t(
                  vatLiable ? 'Reports.TableSection.ExcludeVAT' : 'Reports.TableSection.IncludeVAT'
              )})`
            : '',
    ].filter(Boolean);
    const rows = [columnNames];
    if (reports) {
        reports.forEach((report) =>
            report?.data?.userUsage.map((item: UserUsage) =>
                rows.push(
                    [
                        `${item.user}`,
                        `${item.phone}`,
                        `${item.chargingSessions.amount}`,
                        `${localDecimals(item.occupiedTime.amount ?? 0, 100, 1)}`,
                        `${localDecimals(item.energyConsumption.amount)}`,
                        stripeStatus === StripeStatus.ENABLED
                            ? `${localCurrency(
                                  item.revenue.amount,
                                  item.revenue.unit || defaultCurency
                              )}`
                            : '',
                    ].filter(Boolean)
                )
            )
        );
    }
    generateCsv(
        rows,
        getFilename(
            'User details',
            reports?.map((r) => r?.name ?? ''),
            from,
            to
        )
    );
};

export const downloadVisitorRfidUsageCsv = (
    reports: ReportOutput[] | undefined,
    from: Date,
    to: Date
) => () => {
    if (!reports || reports.length === 0) {
        return;
    }
    const occupiedTimeUnit = reports ? reports[0]?.total?.occupiedTime?.unit : '';
    const energyConsumptionUnit = reports ? reports[0]?.total?.energyConsumption?.unit : '';
    const columnNames = [
        i18n.t('Reports.TableSection.KeyId'),
        i18n.t('Reports.TableSection.Description'),
        i18n.t('Reports.TableSection.Sessions'),
        `${i18n.t('Reports.TableSection.TotalTime')} (${occupiedTimeUnit})`,
        `${i18n.t('Reports.TableSection.TotalEnergy')} (${energyConsumptionUnit})`,
    ];
    const rows = [columnNames];
    if (reports) {
        reports?.forEach((report) =>
            report?.data?.visitorRfidUsage.forEach((item: VisitorRfidUsage) =>
                rows.push([
                    `${item.keyId}`,
                    `${item.description}`,
                    `${item.chargingSessions.amount}`,
                    `${localDecimals(item.occupiedTime.amount ?? 0, 100, 1)}`,
                    `${localDecimals(item.energyConsumption.amount)}`,
                ])
            )
        );
    }
    generateCsv(
        rows,
        getFilename(
            'Visitor Rfid details',
            reports?.map((r) => r?.name ?? ''),
            from,
            to
        )
    );
};

export function hasData(reports: ReportOutput[] | undefined) {
    return reports && reports.length > 0;
}

export function getLabels(
    reports: ReportOutput[] | undefined,
    type: ReportTypeField.EnergyConsumption | ReportTypeField.Revenue
) {
    return hasData(reports)
        ? reports?.at(0)?.perDay[type].map((day: ReportPerDay) => getLocalDay(day.date)) ?? []
        : [];
}

export function getPerDayUnit(
    reports: ReportOutput[] | undefined,
    type: ReportTypeField.EnergyConsumption | ReportTypeField.Revenue
) {
    return reports ? reports.at(0)?.perDay[type].at(0)?.unit ?? '' : '';
}

export function getDataPoints(
    report: ReportOutput,
    type: ReportTypeField.EnergyConsumption | ReportTypeField.Revenue
) {
    return report?.perDay[type].map((item: ReportPerDay) => item.amount) ?? [];
}

export function getTotalDataPoints(
    reports: ReportOutput[] | undefined,
    type: ReportTypeField.EnergyConsumption | ReportTypeField.Revenue
) {
    const data: number[] = [];
    reports?.forEach((report) => {
        report?.perDay[type].forEach((item: ReportPerDay, index: number) => {
            data[index] = (data[index] ?? 0) + item.amount;
        });
    });
    return data;
}

export const ChargingSessionsDataButton = ({
    ids,
    start,
    end,
    unit,
    tid,
    currency,
}: {
    ids: string[];
    start: Date;
    end: Date;
    unit: string;
    tid?: string;
    currency: Currency;
}) => {
    const [getChargingSessions] = useLazyQuery<ChargingSessionData>(CHARGING_SESSIONS);
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const downloadChargingSessionsCsv = async () => {
        setLoading(true);
        const { data } = await getChargingSessions({
            variables: {
                chargesystemIds: ids,
                startDate: formatDateByLocale(start, 'sv-SE'),
                endDate: formatDateByLocale(end, 'sv-SE'),
            },
        });

        const { sessions } = data || {};
        const rows = [sessionsColumnNames(unit)];
        sessions?.forEach((item) =>
            rows.push([
                item.name,
                item.facility,
                item.user,
                item.phone,
                item.keyId,
                item.chargerID,
                `${item.connectorID}`,
                item.startChargingDate,
                item.startChargingTime,
                item.endChargingDate,
                item.endChargingTime,
                `${localDecimals(item.sessionTimeMin ?? 0, 100, 1)}`,
                `${localDecimals(item.energyConsumption ?? 0)}`,
                `${localCurrency(item.sessionAmountIncVAT ?? 0, currency)}`,
                `${localCurrency(item.sessionAmountExVAT ?? 0, currency)}`,
                `${localCurrency(item.vatAmount ?? 0, currency)}`,
                item.paymentProvider,
                item.paymentStatus,
                `${localCurrency(item.cloudChargeTransactionFee ?? 0, currency)}`,
                `${localCurrency(item.payoutAmount ?? 0, currency)}`,
                item.stripeTransactionID ?? '',
                item.paymentID ?? '',
                item.transactionID ?? '',
            ])
        );
        generateCsv(rows, getFilename('Charge sessions', ids, start, end));
        setLoading(false);
    };

    return (
        <MenuItem
            tid={tid}
            color={theme.textColor}
            text={i18n.t('Reports.DownloadChargingSessions')}
            onClick={downloadChargingSessionsCsv}
            disabled={ids.length !== 1 || loading}
            icon="spreadsheet"
        />
    );
};

export function daysBetween(start: Date, end: Date) {
    const diffTime = Math.abs(end.getTime() - start.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export const PayoutReportButton = ({
    ids,
    start,
    end,
    unit,
    tid,
    currency,
}: {
    ids: string[];
    start: Date;
    end: Date;
    unit: string;
    tid?: string;
    currency: Currency;
}) => {
    const [getChargingSessions] = useLazyQuery<PayoutReportData>(PAYOUT_REPORT);
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const download = async () => {
        setLoading(true);
        const { data } = await getChargingSessions({
            variables: {
                chargesystemIds: ids,
                startDate: formatDateByLocale(start, 'sv-SE'),
                endDate: formatDateByLocale(end, 'sv-SE'),
            },
        });

        const { payoutReport } = data || {};
        const rows = [sessionsColumnNames(unit)];
        payoutReport?.forEach((item) =>
            rows.push([
                item.name,
                item.facility,
                item.user,
                item.phone,
                item.keyId,
                item.chargerID,
                `${item.connectorID}`,
                item.startChargingDate,
                item.startChargingTime,
                item.endChargingDate,
                item.endChargingTime,
                `${localDecimals(item.sessionTimeMin ?? 0, 100, 1)}`,
                `${localDecimals(item.energyConsumption ?? 0)}`,
                `${localCurrency(item.sessionAmountIncVAT ?? 0, currency)}`,
                `${localCurrency(item.sessionAmountExVAT ?? 0, currency)}`,
                `${localCurrency(item.vatAmount ?? 0, currency)}`,
                item.paymentProvider,
                item.paymentStatus,
                `${localCurrency(item.cloudChargeTransactionFee ?? 0, currency)}`,
                `${localCurrency(item.payoutAmount ?? 0, currency)}`,
                item.stripeTransactionID ?? '',
                item.paymentID ?? '',
                item.transactionID ?? '',
            ])
        );
        generateCsv(rows, getFilename('Payout reoprt', ids, start, end));
        setLoading(false);
    };

    return (
        <MenuItem
            tid={tid}
            color={theme.textColor}
            text={i18n.t('Reports.DownloadPayoutReport')}
            onClick={download}
            disabled={loading}
            loading={loading}
            icon="spreadsheet"
        />
    );
};
