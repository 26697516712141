import { gql } from '@apollo/client';
import { ChargeSystem, StripeStatus } from '../../models';

export type OrganizationResponse = {
    organization: {
        chargeSystems: ChargeSystem[];
        stripeStatus: StripeStatus;
        stripeAccountId: string;
        vatLiable: boolean;
    };
};

export const GET_ORGANIZATION = gql`
    query GetOrganization($id: ID!) {
        organization(id: $id) {
            chargeSystems {
                id
                name
            }
            stripeStatus
            stripeAccountId
            vatLiable
        }
    }
`;

export const CHARGING_SESSIONS = gql`
    query GetChargingSessions($chargesystemIds: [ID]!, $startDate: String!, $endDate: String!) {
        sessions(
            input: { chargesystemIds: $chargesystemIds, startDate: $startDate, endDate: $endDate }
        ) {
            facility
            name
            user
            phone
            keyId
            chargerID
            connectorID
            startChargingDate
            startChargingTime
            endChargingDate
            endChargingTime
            sessionTimeMin
            energyConsumption
            sessionAmountIncVAT
            sessionAmountExVAT
            vatAmount
            paymentProvider
            paymentStatus
            cloudChargeTransactionFee
            payoutAmount
            stripeTransactionID
            paymentID
            transactionID
        }
    }
`;

export const PAYOUT_REPORT = gql`
    query GetPayoutReport($chargesystemIds: [ID]!, $startDate: String!, $endDate: String!) {
        payoutReport(
            input: { chargesystemIds: $chargesystemIds, startDate: $startDate, endDate: $endDate }
        ) {
            facility
            name
            user
            phone
            keyId
            chargerID
            connectorID
            startChargingDate
            startChargingTime
            endChargingDate
            endChargingTime
            sessionTimeMin
            energyConsumption
            sessionAmountIncVAT
            sessionAmountExVAT
            vatAmount
            paymentProvider
            paymentStatus
            cloudChargeTransactionFee
            payoutAmount
            stripeTransactionID
            paymentID
            transactionID
        }
    }
`;

export const REPORTS_AVAILABLE = gql`
    query GetReportAvailable($chargesystemIds: [ID]!) {
        reportsAvailable(input: $chargesystemIds) {
            year
            month
        }
    }
`;

export const MULTIPLE_REPORTS = gql`
    query GetMultiReport($chargesystemIds: [ID]!, $startDate: String!, $endDate: String!) {
        reports(
            input: { chargesystemIds: $chargesystemIds, startDate: $startDate, endDate: $endDate }
        ) {
            name
            id
            total {
                energyConsumption {
                    amount
                    unit
                }
                revenue {
                    amount
                    unit
                }
                occupationRate {
                    amount
                    unit
                }
                occupiedTime {
                    amount
                    unit
                }
                chargingSessions {
                    amount
                }
            }
            trend {
                energyConsumption {
                    amount
                    unit
                }
                revenue {
                    amount
                    unit
                }
            }
            perDay {
                energyConsumption {
                    date
                    amount
                    unit
                }
                revenue {
                    date
                    amount
                    unit
                }
            }
            data {
                chargerUsage {
                    alias
                    displayName
                    chargingSessions {
                        amount
                    }
                    occupiedTime {
                        amount
                        unit
                    }
                    energyConsumption {
                        amount
                        unit
                    }
                    revenue {
                        amount
                        unit
                    }
                }
                userUsage {
                    user
                    phone
                    chargingSessions {
                        amount
                        unit
                    }
                    occupiedTime {
                        amount
                        unit
                    }
                    energyConsumption {
                        amount
                        unit
                    }
                    revenue {
                        amount
                        unit
                    }
                }
                visitorRfidUsage {
                    keyId
                    description
                    chargingSessions {
                        amount
                        unit
                    }
                    occupiedTime {
                        amount
                        unit
                    }
                    energyConsumption {
                        amount
                        unit
                    }
                    revenue {
                        amount
                        unit
                    }
                }
            }
        }
    }
`;

export const GENERATE_STRIPE_LINK = gql`
    mutation generateStripeLink($input: GenerateStripeAccountLinkInput!) {
        organizations {
            generateStripeAccountLink(input: $input)
        }
    }
`;
