import React from 'react';
import {
    Composite,
    Text,
    TextType,
    Heading,
    Level,
    Currency,
    useTheme,
    Button,
    Size,
    Variant,
} from '@defa/defa-component-library';
import i18n from '../../i18n';
import { ReportOutput, ReportOutputItem, ReportTypeField } from './reports.types';
import { UIState } from '../../models';
import { calculateAmount, getAmountUnit, localCurrency, localDecimals } from './reports.functions';
import { ReportTrend } from './report-trend';

export interface ChartTitleProps {
    children?: React.ReactNode;
    dateRange?: number;
    field: ReportTypeField;
    headerChildren?: React.ReactNode;
    reports?: ReportOutput[];
    title: string;
    uiState: UIState;
    unit: string;
    total: string | number;
}

export function ChartTitle({
    reports,
    uiState,
    title,
    headerChildren,
    children,
    field,
    dateRange = 30,
    total,
    unit,
}: ChartTitleProps) {
    // We only show the trend if we have exactly one report/charge system and the date range is less than 32 days
    // This is because we dont know how/if trend works for longer date ranges
    const showTrend = uiState === UIState.READY && reports?.length === 1 && dateRange <= 31;
    return (
        <Composite fillParent align="start">
            <div>
                <Heading level={Level.h4}>{title}</Heading>
                {uiState === UIState.READY && (
                    <Composite align="start" justify="start">
                        <Heading level={Level.headline}>{total}</Heading>
                        <div>
                            <Text type={TextType.body}>{unit}</Text>
                            {headerChildren}
                        </div>
                    </Composite>
                )}
            </div>
            {showTrend && (
                <ReportTrend reports={reports} field={field}>
                    {children}
                </ReportTrend>
            )}
        </Composite>
    );
}

export function EnergyConsumptionTitle({
    reports,
    uiState,
    dateRange = 30,
}: Pick<ChartTitleProps, 'reports' | 'uiState' | 'dateRange'>) {
    const unit = getAmountUnit(reports, ReportOutputItem.Total, ReportTypeField.EnergyConsumption);
    const total = localDecimals(
        calculateAmount(reports, ReportOutputItem.Total, ReportTypeField.EnergyConsumption)
    );
    return (
        <ChartTitle
            title={i18n.t('Reports.EnergyConsumptionTitle')}
            reports={reports}
            uiState={uiState}
            field={ReportTypeField.EnergyConsumption}
            dateRange={dateRange}
            total={total}
            unit={unit}
        />
    );
}

export function RevenueTitle({
    reports,
    uiState,
    vatLiable = false,
    dateRange = 30,
    onClick,
}: Pick<ChartTitleProps, 'reports' | 'uiState' | 'dateRange'> & {
    onClick: () => void;
    vatLiable: boolean | undefined;
}) {
    const theme = useTheme();
    const unit = getAmountUnit(reports, ReportOutputItem.Total, ReportTypeField.Revenue);
    const total = localCurrency(
        calculateAmount(reports, ReportOutputItem.Total, ReportTypeField.Revenue),
        unit as Currency
    );

    return (
        <ChartTitle
            dateRange={dateRange}
            field={ReportTypeField.Revenue}
            headerChildren={
                <Text type={TextType.body} color={theme.disabledColor}>
                    {i18n.t(vatLiable ? 'Reports.VatLiable' : 'Reports.VatNotLiable')}
                </Text>
            }
            reports={reports}
            title={i18n.t('Reports.RevenueTitle')}
            total={total}
            uiState={uiState}
            unit={unit}
        >
            <Button
                size={Size.SMALL}
                variant={Variant.TEXT}
                fillParent={false}
                padding="0"
                onClick={onClick}
                iconAfter="externalLink"
                iconUseMargin
            >
                {i18n.t('PaymentMethodSettings.Stripe.ActivatedText')}
            </Button>
        </ChartTitle>
    );
}
