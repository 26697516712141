import React from 'react';
import { Composite, Heading, Level, Card, Size, SimpleTable } from '@defa/defa-component-library';
import i18n from '../../i18n';
import { ReportOutput, SessionItem } from './reports.types';
import { StripeStatus, UIState } from '../../models';
import { TableSectionWrapper } from './reports.styles';
import {
    localCurrency,
    localDecimals,
    chargerUsageColumnNames,
    chargerUsageColumns,
} from './reports.functions';

export interface ChargerUsageProps {
    reports: ReportOutput[];
    uiState: UIState;
    getReports: () => void;
    defaultCurrency: string | null;
    stripeStatus?: StripeStatus;
}

export function ChargerUsage({
    reports,
    uiState,
    getReports,
    defaultCurrency = '',
    stripeStatus = StripeStatus.DISABLED,
}: ChargerUsageProps) {
    if (reports.length !== 1) {
        return <></>;
    }

    const chargerUsage = [...(reports.at(0)?.data?.chargerUsage ?? [])].sort((a, b) =>
        a?.alias?.localeCompare(b?.alias, navigator.language)
    );
    const position = uiState === UIState.READY ? 'start' : 'center';

    return (
        <TableSectionWrapper>
            <Card
                size={Size.LARGE}
                clickable={false}
                loading={uiState === UIState.LOADING}
                error={uiState === UIState.ERROR}
                errorButtonText={i18n.t('Reports.ReloadButtonText')}
                errorButtonOnClick={getReports}
                alignChildren={position}
                justifyChildren={position}
                title={
                    <Composite>
                        <Heading level={Level.h4}>
                            {i18n.t('Reports.ChargerUsageDetailsTitle')}
                        </Heading>
                    </Composite>
                }
            >
                {chargerUsage.length > 0 && (
                    <SimpleTable<SessionItem>
                        items={chargerUsage.map((item) => ({
                            id: item.alias,
                            displayName: item.displayName,
                            sessions: item.chargingSessions.amount,
                            totalTime: `${localDecimals(item.occupiedTime.amount, 100, 1)} ${
                                item.occupiedTime.unit
                            }`,
                            totalEnergy: `${localDecimals(item.energyConsumption.amount)} ${
                                item.energyConsumption.unit
                            }`,
                            revenue: `${localCurrency(
                                item.revenue.amount,
                                item.revenue.unit || defaultCurrency
                            )}`,
                        }))}
                        columns={chargerUsageColumns(stripeStatus)}
                        columnNames={chargerUsageColumnNames(stripeStatus)}
                    />
                )}
            </Card>
        </TableSectionWrapper>
    );
}
