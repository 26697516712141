import React, { useRef } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TextAlign,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { formatInitials, getColorByInitials, useTheme } from '@defa/defa-component-library';
import { ChartProps, ReportTypeField } from './reports.types';
import i18n from '../../i18n';
import {
    ExcludesUndefined,
    getDataPoints,
    getLabels,
    getTotalDataPoints,
    getPerDayUnit,
} from './reports.functions';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = (showLabels: boolean = false) => ({
    responsive: true,
    maintainAspectRatio: false,
    animation: {
        duration: 0,
    },
    plugins: {
        tooltip: {
            titleAlign: 'center' as TextAlign,
            bodyAlign: 'center' as TextAlign,
            padding: 8,
            callbacks: {
                title: (context: any) => {
                    const [current] = context;
                    return current.dataset.dates[current.dataIndex];
                },
                label: (context: any) =>
                    `${showLabels ? context.dataset.label : ''} ${context.formattedValue} ${
                        context.dataset.unit
                    }`,
            },
        },
        legend: {
            display: showLabels,
            position: 'bottom' as TextAlign,
        },
    },
    scales: {
        x: {
            grid: {
                color: 'rgba(242, 242, 244, 1)',
            },
            ticks: {
                maxRotation: 0,
                minRotation: 0,
            },
        },
        y: {
            grid: {
                color: 'rgba(242, 242, 244, 1)',
            },
            ticks: {
                autoSkip: false,
            },
            beginAtZero: true,
        },
    },
});

interface ChartDataObject {
    labels: string[];
    datasets: any[];
}

export function EnergyConsumptionChart({ reports = [], id }: ChartProps) {
    const chartRef = useRef<any>(null);
    const theme = useTheme();
    const dates = getLabels(reports, ReportTypeField.EnergyConsumption);
    const unit = getPerDayUnit(reports, ReportTypeField.EnergyConsumption);

    const datasets = [
        {
            label: i18n.t('Reports.Total'),
            data: getTotalDataPoints(reports, ReportTypeField.EnergyConsumption),
            fill: true,
            borderColor: theme.colorPrimary,
            backgroundColor: theme.colorPrimary,
            tension: 0.1,
            dates,
            unit,
        },
        ...(reports.length > 1
            ? reports.map((report) => ({
                  label: `${report?.name}`,
                  data: getDataPoints(report, ReportTypeField.EnergyConsumption),
                  borderColor: getColorByInitials(formatInitials(report?.name), theme),
                  backgroundColor: getColorByInitials(formatInitials(report?.name), theme),
                  tension: 0.1,
                  dates,
                  unit,
              }))
            : []),
    ].filter((Boolean as any) as ExcludesUndefined);
    const data: ChartDataObject = {
        labels: dates,
        datasets,
    };

    return (
        <Line ref={chartRef} datasetIdKey={id} options={options(reports.length > 1)} data={data} />
    );
}
