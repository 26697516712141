import React, { useState } from 'react';
import {
    Button,
    ContextWindow,
    RangePicker,
    SideMenuItem,
    Size,
    TextInput,
    Variant,
} from '@defa/defa-component-library';
import { getLocalDate } from './reports.functions';
import { Root, Navigation, Dates, DateContainer } from './report-date-selection.styles';
import i18n from '../../i18n';

export interface ReportDateSelectionProps {
    start: Date;
    end: Date;
    max: Date;
    min: Date;
    onCalendarDateChange: (dates: [Date, Date]) => void;
}

export function ReportDateSelection({
    start,
    end,
    min,
    max,
    onCalendarDateChange,
}: ReportDateSelectionProps) {
    const today = new Date();
    const [startDate, setStartDate] = useState(start);
    const [endDate, setEndDate] = useState(end > today ? today : end);
    const [menuPositioningElement, setMenuPositioningElement] = useState<HTMLElement | undefined>(
        undefined
    );

    const onChange = (dates: [Date, Date]) => {
        const [s, e] = dates;
        setStartDate(s);
        setEndDate(e);
        onCalendarDateChange(dates);
    };

    return (
        <>
            <Button
                iconAfter="chevron"
                iconUseMargin
                showInPrint
                fillParent={false}
                size={Size.SMALL}
                variant={Variant.SECONDARY}
                onClick={(e?: MouseEvent) => {
                    const { target } = e || ({} as MouseEvent);
                    setMenuPositioningElement(
                        menuPositioningElement ? undefined : (target as HTMLElement)
                    );
                }}
            >
                {getLocalDate(start)} - {getLocalDate(end)}
            </Button>
            <ContextWindow
                noPadding
                positioningElement={menuPositioningElement}
                onClosePress={() => {
                    setMenuPositioningElement(undefined);
                }}
                show={!!menuPositioningElement}
                autoHeight
                maxWidth="100%"
                side="bottom"
                bodyContent={
                    <Root>
                        <Navigation>
                            <SideMenuItem
                                text={i18n.t('Reports.Last7Days')}
                                href="#"
                                onClick={() => {
                                    const newStartDate = new Date(
                                        today.getFullYear(),
                                        today.getMonth(),
                                        today.getDate() - 6
                                    );
                                    onChange([newStartDate, today]);
                                }}
                            />
                            <SideMenuItem
                                onClick={() => {
                                    const newStartDate = new Date(
                                        today.getFullYear(),
                                        today.getMonth(),
                                        today.getDate() - 30
                                    );
                                    onChange([newStartDate, today]);
                                }}
                                text={i18n.t('Reports.Last30Days')}
                                href="#"
                            />
                            <SideMenuItem
                                href="#"
                                onClick={() => {
                                    const newStartDate = new Date(
                                        today.getFullYear(),
                                        today.getMonth(),
                                        1
                                    );
                                    onChange([newStartDate, today]);
                                }}
                                text={i18n.t('Reports.ThisMonth')}
                            />
                            <SideMenuItem
                                onClick={() => {
                                    const newStartDate = new Date(
                                        today.getFullYear(),
                                        today.getMonth() - 1,
                                        1
                                    );
                                    const newEndDate = new Date(
                                        today.getFullYear(),
                                        today.getMonth(),
                                        0
                                    );
                                    onChange([newStartDate, newEndDate]);
                                }}
                                text={i18n.t('Reports.LastMonth')}
                                href="#"
                            />
                            <SideMenuItem
                                onClick={() => {
                                    const newStartDate = new Date(
                                        today.getFullYear(),
                                        today.getMonth() - 2,
                                        1
                                    );
                                    const newEndDate = new Date(
                                        today.getFullYear(),
                                        today.getMonth(),
                                        0
                                    );
                                    onChange([newStartDate, newEndDate]);
                                }}
                                text={i18n.t('Reports.Last2Months')}
                                href="#"
                            />
                            <SideMenuItem
                                onClick={() => {
                                    const newStartDate = new Date(
                                        today.getFullYear(),
                                        today.getMonth() - 3,
                                        1
                                    );
                                    const newEndDate = new Date(
                                        today.getFullYear(),
                                        today.getMonth(),
                                        0
                                    );
                                    onChange([newStartDate, newEndDate]);
                                }}
                                text={i18n.t('Reports.LastQuarter')}
                                href="#"
                            />
                        </Navigation>
                        <DateContainer>
                            <Dates>
                                <TextInput
                                    label={i18n.t('Reports.StartDate')}
                                    value={getLocalDate(startDate)}
                                    name="startDate"
                                    disabled
                                />
                                <TextInput
                                    label={i18n.t('Reports.EndDate')}
                                    value={getLocalDate(endDate)}
                                    name="endDate"
                                    disabled
                                />
                            </Dates>
                            <RangePicker
                                minDate={min}
                                maxDate={max}
                                start={startDate}
                                end={endDate}
                                onChange={onChange}
                                inline
                                locale="sv-SE"
                                DATA-TODO="FIX-DATEPICKER-I18N"
                            />
                        </DateContainer>
                    </Root>
                }
            />
        </>
    );
}
