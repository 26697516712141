import { styled } from '@defa/defa-component-library';

export const Root = styled.div`
    display: flex;
    align-items: center;
    padding: ${({ theme }) => theme.spacing(5)};
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.border.alternative};
    &:last-child {
        border-bottom: none;
    }

    & > * {
        margin: 0;
    }
`;

export const MiniAvatar = styled.div<{ color: string }>`
    min-width: ${({ theme }) => theme.spacing(6)};
    height: ${({ theme }) => theme.spacing(6)};
    margin: 0;
    padding: 0;
    background-color: ${({ color }) => `${color}`};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ theme }) => theme.spacing(2.5)};
    color: ${({ theme }) => theme.foreground.light};
    font-weight: 700;

    border-radius: ${({ theme }) => theme.spacing(1.5)};

    @media print {
        display: none;
    }
`;

export const Text = styled.label`
    display: block;
    background: none;
    border: none;
    padding: 0 ${({ theme }) => theme.spacing(3)};
    cursor: pointer;
    margin: 0;
    font-size: ${({ theme }) => theme.descriptionSize};
    line-height: 1;
`;

export const Input = styled.input`
    margin-left: auto;
`;

export const InfoBoxRoot = styled.div<{ color: string }>`
    padding: ${({ theme }) => theme.spacing(2)};
    background-color: ${({ color }) => `${color}`};

    border-radius: ${({ theme }) => theme.spacing(2)};

    & > * {
        color: #fff;
    }

    @media print {
        display: none;
    }
`;
