import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
    Button,
    Card,
    Composite,
    Group,
    Heading,
    Level,
    Size,
    Text,
    Variant,
    ProgressIndicator,
    ProgressType,
} from '@defa/defa-component-library';
import React, { useCallback, useEffect, useState } from 'react';
import { Header } from '../../fractions/header';
import { EnergyConsumptionChart } from './energy-consumption-chart';
import {
    ChartOneSectionWrapper,
    ChartTwoSectionWrapper,
    Content,
    Root,
    ChartContainer,
} from './reports.styles';
import { RevenueChart } from './revenue-chart';
import i18n from '../../i18n';
import { useOrganization } from '../../utils/hooks';
import {
    GENERATE_STRIPE_LINK,
    GET_ORGANIZATION,
    OrganizationResponse,
    MULTIPLE_REPORTS,
} from './reports.queries';
import {
    ReportData,
    ReportOutputItem,
    ReportTypeField,
    StripeAccountLinkData,
} from './reports.types';
import { UIState } from '../../models/ui';
import { StripeStatus as STRIPE_STATUS } from '../../models/organization';
import { ChargeSystemSelection } from './charge-system-selection';
import { ReportMenu } from './report-menu';
import { ReportDateSelection } from './report-date-selection';
import { EnergyConsumptionTitle, RevenueTitle } from './chart-title';
import { ChargerUsage } from './charger-usage';
import { UserUsage } from './user-usage';
import { RfidUsage } from './rfid-usage';
import {
    daysBetween,
    calculateAverageAmount,
    localDecimals,
    calculateAmount,
    getAmountUnit,
} from './reports.functions';
import { formatDateByLocale } from '../../utils/date';

export const Reports: React.FunctionComponent = () => {
    const [organizationId] = useOrganization();
    const { data } = useQuery<OrganizationResponse>(GET_ORGANIZATION, {
        variables: {
            id: organizationId,
        },
    });
    const { organization } = data || {};

    const [getReports, { data: reportData, error: multiError }] = useLazyQuery<ReportData>(
        MULTIPLE_REPORTS
    );

    const now = new Date();
    const [start, setStart] = useState<Date>(new Date(now.getFullYear(), now.getMonth(), 1));
    const [end, setEnd] = useState<Date>(new Date(now.getFullYear(), now.getMonth() + 1, 0));
    const [min] = useState<Date>(new Date(now.getFullYear()));
    const [max] = useState<Date>(new Date());
    const [uiState, setUiState] = useState<UIState>(UIState.LOADING);
    const [csIds, setCsIds] = useState<string[]>([]);

    const [generateLink, { data: linkData }] = useMutation<StripeAccountLinkData>(
        GENERATE_STRIPE_LINK
    );

    const { stripeStatus, stripeAccountId, vatLiable, chargeSystems } = organization || {};
    const { organizations: orgsForLink } = linkData || {};
    const [orgForLink] = orgsForLink || [];
    const { generateStripeAccountLink } = orgForLink || organization || {};

    useEffect(() => {
        if (generateStripeAccountLink && stripeStatus === STRIPE_STATUS.ENABLED) {
            window.open(generateStripeAccountLink);
        }
    }, [generateStripeAccountLink, stripeStatus]);

    const getAllReports = useCallback(() => {
        setUiState(UIState.LOADING);
        getReports({
            variables: {
                chargesystemIds: csIds,
                startDate: formatDateByLocale(start, 'sv-SE'),
                endDate: formatDateByLocale(end, 'sv-SE'),
            },
        })
            .then(() => {
                setUiState(UIState.READY);
            })
            .catch((err) => {
                setUiState(UIState.ERROR);
                console.error('getReports', { err });
            });
    }, [csIds, getReports, start, end, setUiState]);

    useEffect(() => {
        if (csIds.length === 0) {
            return;
        }
        getAllReports();
    }, [csIds, getAllReports]);

    useEffect(() => {
        if (chargeSystems && chargeSystems.length > 0 && csIds.length === 0) {
            setCsIds([chargeSystems.at(0)?.id ?? '']);
        }
    }, [csIds, chargeSystems]);

    useEffect(() => {
        if (multiError) {
            setUiState(UIState.ERROR);
        }
    }, [multiError]);

    useEffect(() => (reportData ? setUiState(UIState.READY) : setUiState(UIState.LOADING)), [
        reportData,
    ]);

    const { reports } = reportData || {};
    const defaultCurrency = reports
        ? reports[0]?.data?.chargerUsage.find((c) => c?.revenue?.unit.toString() !== '')?.revenue
              ?.unit || null
        : null;
    const daysBetweenSelectedDates = daysBetween(start, end);

    return (
        <Root>
            <Header />
            <Group verticalMargin="40px">
                <Composite fillParent>
                    <ChargeSystemSelection
                        activeIds={csIds}
                        chargeSystems={chargeSystems}
                        onChange={setCsIds}
                    />
                    <Composite justify="end">
                        <ReportDateSelection
                            start={start}
                            end={end}
                            min={min}
                            max={max}
                            onCalendarDateChange={(dates) => {
                                const [newStartDate, newEndDate] = dates;
                                setEnd(newEndDate);
                                setStart(newStartDate);
                            }}
                        />
                        <ReportMenu
                            reports={reports}
                            uiState={uiState}
                            chargeSystemIds={csIds}
                            defaultCurrency={defaultCurrency}
                            start={start}
                            end={end}
                            stripeStatus={stripeStatus}
                            vatLiable={vatLiable}
                        />
                    </Composite>
                </Composite>
            </Group>
            <Content>
                <ChartOneSectionWrapper>
                    <Card
                        clickable={false}
                        size={Size.LARGE}
                        loading={uiState === UIState.LOADING}
                        alignChildren="center"
                        justifyChildren="center"
                        error={uiState === UIState.ERROR}
                        errorButtonText={i18n.t('Reports.ReloadButtonText')}
                        errorButtonOnClick={getAllReports}
                        title={
                            <EnergyConsumptionTitle
                                reports={reports}
                                uiState={uiState}
                                dateRange={daysBetweenSelectedDates}
                            />
                        }
                    >
                        <ChartContainer>
                            <EnergyConsumptionChart
                                label={i18n.t('Reports.EnergyConsumptionTitle')}
                                id="EnergyConsumption"
                                reports={reports}
                            />
                        </ChartContainer>
                    </Card>
                </ChartOneSectionWrapper>
                <ChartTwoSectionWrapper>
                    {stripeStatus === STRIPE_STATUS.ENABLED && (
                        <Card
                            clickable={false}
                            size={Size.LARGE}
                            loading={uiState === UIState.LOADING}
                            alignChildren="start"
                            justifyChildren="start"
                            error={uiState === UIState.ERROR}
                            errorButtonText={i18n.t('Reports.ReloadButtonText')}
                            errorButtonOnClick={getAllReports}
                            title={
                                <RevenueTitle
                                    vatLiable={vatLiable}
                                    reports={reports}
                                    uiState={uiState}
                                    dateRange={daysBetweenSelectedDates}
                                    onClick={() =>
                                        generateLink({
                                            variables: {
                                                input: {
                                                    stripeAccountId,
                                                },
                                            },
                                        })
                                    }
                                />
                            }
                        >
                            <ChartContainer>
                                <RevenueChart
                                    label={i18n.t('Reports.RevenueTitle')}
                                    id="Revenue"
                                    reports={reports}
                                />
                            </ChartContainer>
                        </Card>
                    )}
                </ChartTwoSectionWrapper>
                <Card
                    clickable={false}
                    size={Size.LARGE}
                    loading={uiState === UIState.LOADING}
                    error={uiState === UIState.ERROR}
                    errorButtonText={i18n.t('Reports.ReloadButtonText')}
                    errorButtonOnClick={getAllReports}
                    alignChildren={uiState === UIState.READY ? 'end' : 'center'}
                    justifyChildren={uiState === UIState.ERROR ? 'center' : 'inherit'}
                    title={
                        <Composite align="start">
                            <Heading level={Level.h4}>
                                {i18n.t('Reports.ChargingSessionsTitle')}
                            </Heading>
                        </Composite>
                    }
                >
                    <Heading level={Level.h1}>
                        {calculateAmount(
                            reports,
                            ReportOutputItem.Total,
                            ReportTypeField.ChargingSessions
                        )}
                    </Heading>
                </Card>
                <Card
                    clickable={false}
                    size={Size.LARGE}
                    loading={uiState === UIState.LOADING}
                    error={uiState === UIState.ERROR}
                    errorButtonText={i18n.t('Reports.ReloadButtonText')}
                    errorButtonOnClick={getAllReports}
                    alignChildren={uiState === UIState.READY ? 'end' : 'center'}
                    justifyChildren={uiState === UIState.ERROR ? 'center' : 'inherit'}
                    title={
                        <Heading level={Level.h4}>
                            {i18n.t('Reports.TotalOccupiedTimeTitle')}
                        </Heading>
                    }
                >
                    <Composite align="start">
                        <Heading level={Level.h1}>
                            {localDecimals(
                                calculateAmount(
                                    reports,
                                    ReportOutputItem.Total,
                                    ReportTypeField.OccupiedTime
                                ),
                                100,
                                1
                            )}
                        </Heading>
                        <Text>
                            {getAmountUnit(
                                reports,
                                ReportOutputItem.Total,
                                ReportTypeField.OccupiedTime
                            )}
                        </Text>
                    </Composite>
                </Card>
                <Card
                    clickable={false}
                    size={Size.LARGE}
                    loading={uiState === UIState.LOADING}
                    error={uiState === UIState.ERROR}
                    errorButtonText={i18n.t('Reports.ReloadButtonText')}
                    errorButtonOnClick={getAllReports}
                    alignChildren={uiState === UIState.READY ? 'end' : 'center'}
                    justifyChildren={uiState === UIState.ERROR ? 'center' : 'inherit'}
                    title={
                        <Composite align="start">
                            <Heading level={Level.h4}>
                                {i18n.t('Reports.OccupationRateTitle')}
                            </Heading>
                            <Button
                                size={Size.TINY}
                                variant={Variant.TERTIARY}
                                fillParent={false}
                                iconAfter="question"
                                tooltip={i18n.t('Reports.OccupationRateTooltip')}
                            />
                        </Composite>
                    }
                >
                    <Composite align="start">
                        <ProgressIndicator
                            progress={calculateAverageAmount(
                                reports,
                                ReportOutputItem.Total,
                                ReportTypeField.OccupationRate
                            )}
                            type={ProgressType.DONUT}
                        />
                        <Heading level={Level.h1}>
                            {calculateAverageAmount(
                                reports,
                                ReportOutputItem.Total,
                                ReportTypeField.OccupationRate
                            ).toFixed()}
                        </Heading>
                        <Text>
                            {getAmountUnit(
                                reports,
                                ReportOutputItem.Total,
                                ReportTypeField.OccupationRate
                            )}
                        </Text>
                    </Composite>
                </Card>

                {reports?.length === 1 && (
                    <>
                        <ChargerUsage
                            reports={reports}
                            uiState={uiState}
                            getReports={getAllReports}
                            defaultCurrency={defaultCurrency}
                            stripeStatus={stripeStatus}
                        />

                        <UserUsage
                            reports={reports}
                            uiState={uiState}
                            getReports={getAllReports}
                            defaultCurrency={defaultCurrency}
                            stripeStatus={stripeStatus}
                        />

                        <RfidUsage reports={reports} uiState={uiState} getReports={getAllReports} />
                    </>
                )}
            </Content>
        </Root>
    );
};
