import {
    Button,
    ContextWindow,
    Currency,
    Size,
    useTheme,
    Variant,
} from '@defa/defa-component-library';
import React, { useState } from 'react';
import i18n from '../../i18n';
import { ReportOutput } from './reports.types';
import { UIState, StripeStatus } from '../../models';
import {
    ChargingSessionsDataButton,
    downloadChargerUsageCsv,
    downloadUserUsageCsv,
    downloadVisitorRfidUsageCsv,
    PayoutReportButton,
} from './reports.functions';
import { MenuItem } from '../../fractions/header';

export interface ReportMenuProps {
    reports?: ReportOutput[];
    uiState: UIState;
    chargeSystemIds: string[];
    defaultCurrency?: string | null;
    start: Date;
    end: Date;
    stripeStatus?: StripeStatus;
    vatLiable?: boolean;
}

export function ReportMenu({
    reports,
    uiState,
    chargeSystemIds,
    defaultCurrency,
    start = new Date(),
    end = new Date(),
    stripeStatus = StripeStatus.DISABLED,
    vatLiable = false,
}: ReportMenuProps) {
    const theme = useTheme();
    const [menuPositioningElement, setMenuPositioningElement] = useState<HTMLElement | undefined>(
        undefined
    );

    const unit = reports ? reports.at(0)?.total?.energyConsumption?.unit ?? '' : '';
    const currency = (defaultCurrency as Currency) ?? '';

    return (
        <>
            <Button
                name="download-menu-button"
                size={Size.SMALL}
                variant={Variant.SECONDARY}
                fillParent={false}
                iconAfter="chevron"
                iconUseMargin
                disabled={uiState !== UIState.READY}
                onClick={(e?: MouseEvent) => {
                    const { target } = e || ({} as MouseEvent);
                    setMenuPositioningElement(
                        menuPositioningElement ? undefined : (target as HTMLElement)
                    );
                }}
            >
                {i18n.t('Reports.DownloadButton')}
            </Button>

            <ContextWindow
                positioningElement={menuPositioningElement}
                onClosePress={() => {
                    setMenuPositioningElement(undefined);
                }}
                show={!!menuPositioningElement}
                autoHeight
                side="bottom"
                bodyContent={
                    <>
                        <MenuItem
                            tid="download-pdf-button"
                            color={theme.textColor}
                            onClick={() => {
                                window.print();
                            }}
                            text={i18n.t('Reports.DownloadPdfButton')}
                            icon="spreadsheet"
                        />
                        <ChargingSessionsDataButton
                            tid="download-charging-sessions"
                            ids={chargeSystemIds}
                            start={start}
                            end={end}
                            unit={unit}
                            currency={currency}
                        />
                        <MenuItem
                            tid="download-charger-usage"
                            color={theme.textColor}
                            onClick={downloadChargerUsageCsv(
                                reports,
                                stripeStatus,
                                vatLiable,
                                currency,
                                start,
                                end
                            )}
                            text={i18n.t('Reports.DownloadChargerUsage')}
                            icon="spreadsheet"
                        />
                        <MenuItem
                            tid="download-user-usage"
                            color={theme.textColor}
                            onClick={downloadUserUsageCsv(
                                reports,
                                stripeStatus,
                                vatLiable,
                                currency,
                                start,
                                end
                            )}
                            text={i18n.t('Reports.DownloadUserUsage')}
                            icon="spreadsheet"
                        />
                        <MenuItem
                            color={theme.textColor}
                            tid="download-rfid-usage"
                            onClick={downloadVisitorRfidUsageCsv(reports, start, end)}
                            text={i18n.t('Reports.DownloadRfidUsage')}
                            icon="spreadsheet"
                        />
                        <PayoutReportButton
                            tid="download-payout-report"
                            ids={chargeSystemIds}
                            start={start}
                            end={end}
                            unit={unit}
                            currency={currency}
                        />
                    </>
                }
            />
        </>
    );
}
