import React from 'react';
import { Composite, Icon, useTheme, Text, TextType } from '@defa/defa-component-library';
import i18n from '../../i18n';
import { ReportOutput, ReportTypeField, ReportOutputItem } from './reports.types';
import { calculateAverageAmount, getAmountUnit } from './reports.functions';

export interface TrendProps {
    reports?: ReportOutput[];
    children?: React.ReactNode;
    field: ReportTypeField;
}

export function ReportTrend({ reports, children, field }: TrendProps) {
    const theme = useTheme();
    const amount = calculateAverageAmount(reports, ReportOutputItem.Trend, field);
    const unit = getAmountUnit(reports, ReportOutputItem.Trend, field);
    return (
        <div>
            <Composite justify="end">
                {amount >= 0 ? (
                    <Icon size={16} icon="arrowUp" color={theme.successColor} />
                ) : (
                    <Icon size={16} icon="arrowDown" color={theme.errorColor} />
                )}
                <Text color={amount >= 0 ? theme.successColor : theme.errorColor}>
                    {amount.toFixed(2)}
                    {unit}
                </Text>
            </Composite>
            <Text type={TextType.descriptionSmall} color={theme.disabledColor}>
                {i18n.t('Reports.TrendDescription')}
            </Text>
            {children}
        </div>
    );
}
