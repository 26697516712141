import { styled } from '@defa/defa-component-library';

export const Root = styled.div`
    display: flex;
    flex: 1;
    align-items: space-between;
    flex-direction: row;
    background-color: ${({ theme }) => theme.background.subtle};
    min-width: 400px;
    margin: 0;

    & > * {
        margin: 0;
    }

    @media print {
        & canvas {
            min-height: 100%;
            max-width: 100%;
            max-height: 100%;
            height: auto !important;
            width: auto !important;
        }
    }
`;

export const Navigation = styled.div`
    display: flex;
    flex: 1;
    align-items: start;
    flex-direction: column;
    background-color: ${({ theme }) => theme.background.default};
    min-width: max-content;
    padding: ${({ theme }) => theme.spacing(4)};
`;

export const DateContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    max-width: 100vw;
    padding: ${({ theme }) => theme.spacing(5)};
    gap: ${({ theme }) => theme.spacing(3)};
`;

export const Dates = styled.div`
    display: flex;
    flex: 1;
    align-items: stretch;
    flex-direction: rows;
    max-width: 100vw;
    gap: ${({ theme }) => theme.spacing(3)};
`;
