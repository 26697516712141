import { Currency } from '@defa/defa-component-library';

export interface SessionItem {
    id: string;
    sessions: number;
    totalTime: string;
    totalEnergy: string;
    revenue: string;
    user?: string;
    phone?: string;
    keyId?: string;
    description?: string;
}
interface CurrencyAmount extends Omit<UnitAmount, 'unit'> {
    unit: Currency;
}
export interface UnitAmount {
    amount: number;
    unit?: string;
}
export interface ChargerUsage {
    alias: string;
    displayName?: string;
    chargingSessions: UnitAmount;
    occupiedTime: UnitAmount;
    energyConsumption: UnitAmount;
    revenue: CurrencyAmount;
}
export interface UserUsage {
    user: string;
    phone: string;
    chargingSessions: UnitAmount;
    occupiedTime: UnitAmount;
    energyConsumption: UnitAmount;
    revenue: CurrencyAmount;
}
export interface VisitorRfidUsage {
    keyId: string;
    description: string;
    chargingSessions: UnitAmount;
    occupiedTime: UnitAmount;
    energyConsumption: UnitAmount;
}

export interface ChargingSession {
    facility: string;
    name: string;
    user: string;
    phone: string;
    keyId: string;
    chargerID: string;
    connectorID: number;
    startChargingDate: string;
    startChargingTime: string;
    endChargingDate: string;
    endChargingTime: string;
    sessionTimeMin: number;
    energyConsumption: number;
    sessionAmountIncVAT: number;
    sessionAmountExVAT: number;
    vatAmount: number;
    paymentProvider: string;
    paymentStatus: string;
    cloudChargeTransactionFee: number;
    payoutAmount: number;
    stripeTransactionID: string;
    paymentID?: string;
    transactionID?: string;
}

export interface ChartProps {
    reports: ReportOutput[];
    label: string;
    id: string;
}

export interface ReportPerDay {
    date: string;
    amount: number;
    unit?: string;
}
export interface ReportPerDayType {
    energyConsumption: ReportPerDay[];
    revenue: ReportPerDay[];
}
export interface ReportDataOutput {
    chargerUsage: ChargerUsage[];
    userUsage: UserUsage[];
    visitorRfidUsage: VisitorRfidUsage[];
}
export enum ReportTypeField {
    EnergyConsumption = 'energyConsumption',
    Revenue = 'revenue',
    OccupiedTime = 'occupiedTime',
    ChargingSessions = 'chargingSessions',
    OccupationRate = 'occupationRate',
}
export interface ReportType {
    energyConsumption: UnitAmount;
    revenue: UnitAmount;
    occupiedTime: UnitAmount;
    chargingSessions: UnitAmount;
    occupationRate: UnitAmount;
}

export enum ReportOutputItem {
    Total = 'total',
    Trend = 'trend',
}
export interface ReportOutput {
    id?: string;
    name?: string;
    total: ReportType;
    trend: ReportType;
    perDay: ReportPerDayType;
    data?: ReportDataOutput;
}
export interface ReportData {
    reports: ReportOutput[];
}

export interface DateOutput {
    year: number;
    month: number;
}

export interface ReportDataAvailable {
    chargeSystem: {
        reportAvailable: DateOutput;
    };
}

export interface ChargingSessionData {
    sessions: ChargingSession[];
}

export interface PayoutReportData {
    payoutReport: ChargingSession[];
}

export interface StripeAccountLink {
    generateStripeAccountLink: string;
}
export interface StripeAccountLinkData {
    organizations: StripeAccountLink[];
}
