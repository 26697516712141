export function formatDateByLocale(date: Date, locale = navigator.language) {
    const formater = new Intl.DateTimeFormat(locale, {
        dateStyle: 'short',
    });
    return formater.format(date);
}

export function formatTimeByLocale(date: Date) {
    const formater = new Intl.DateTimeFormat(navigator.language, {
        dateStyle: undefined,
        timeStyle: 'short',
    });
    return formater.format(date);
}

export function getDateByTimeStamp(timeStamp: string) {
    const date = new Date(parseInt(timeStamp, 10));
    return formatDateByLocale(date);
}

export function getTimeByTimestamp(timeStamp: string) {
    const date = new Date(parseInt(timeStamp, 10));
    return formatTimeByLocale(date);
}

export function localizeUnserializedDate(dateTime: string) {
    const date = new Date(dateTime);
    const formater = new Intl.DateTimeFormat(navigator.language, {
        dateStyle: 'medium',
        timeStyle: 'medium',
    });
    return formater.format(date);
}
