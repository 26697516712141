import React from 'react';
import { Composite, Heading, Level, Card, Size, SimpleTable } from '@defa/defa-component-library';
import i18n from '../../i18n';
import { ReportOutput, SessionItem } from './reports.types';
import { UIState } from '../../models';
import { TableSectionWrapper } from './reports.styles';
import {
    localDecimals,
    visitorRfidUsageColumnNames,
    visitorRfidUsageColumns,
} from './reports.functions';

export interface RfidUsageProps {
    reports: ReportOutput[];
    uiState: UIState;
    getReports: () => void;
}

export function RfidUsage({ reports, uiState, getReports }: RfidUsageProps) {
    if (reports.length !== 1) {
        return <></>;
    }
    const rfidUsage = [...(reports.at(0)?.data?.visitorRfidUsage ?? [])].sort((a, b) =>
        a?.keyId?.localeCompare(b?.keyId, navigator.language)
    );

    return (
        <TableSectionWrapper>
            <Card
                size={Size.LARGE}
                clickable={false}
                loading={uiState === UIState.LOADING}
                error={uiState === UIState.ERROR}
                errorButtonText={i18n.t('Reports.ReloadButtonText')}
                errorButtonOnClick={getReports}
                alignChildren={uiState === UIState.READY ? 'start' : 'center'}
                justifyChildren={uiState === UIState.READY ? 'start' : 'center'}
                title={
                    <Composite>
                        <Heading level={Level.h4}>
                            {i18n.t('Reports.VisitorRfidUsageDetailsTitle')}
                        </Heading>
                    </Composite>
                }
            >
                {rfidUsage.length > 0 && (
                    <SimpleTable<SessionItem>
                        items={rfidUsage.map((item) => ({
                            id: item.keyId,
                            keyId: item.keyId,
                            description: item.description,
                            sessions: item.chargingSessions.amount,
                            totalTime: `${localDecimals(item.occupiedTime.amount, 100, 1)} ${
                                item.occupiedTime.unit
                            }`,
                            totalEnergy: `${localDecimals(item.energyConsumption.amount)} ${
                                item.energyConsumption.unit
                            }`,
                            revenue: '',
                        }))}
                        columns={visitorRfidUsageColumns}
                        columnNames={visitorRfidUsageColumnNames}
                    />
                )}
            </Card>
        </TableSectionWrapper>
    );
}
